import Swal from 'sweetalert2';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SalesinvoiceService } from 'src/app/erp/services/salesinvoice/salesinvoice.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ItemcomponentComponent } from '../itemcomponent/itemcomponent.component';
import { DatePipe } from '@angular/common';
import { CustomerService } from '../../services/customer/customer.service';
import { SupplierService } from '../../services/supplier/suppier.service';
import { ItemcomponentService } from '../../services/itemcomponent/itemcomponent.service';
import { StockReportsService } from '../../services/reports/stockreports/stockreports.service';
import { SalesSourceService } from '../../services/sales-source.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ExportexcelService } from 'src/app/services/exportexcel/exportexcel.service';
import * as  localForage from 'localforage';
import { CommonService } from 'src/app/services/common/common.service';
import { InventoryService } from '../../services/inventory/inventory.service';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-sales-invoice',
  templateUrl: './sales-invoice.component.html',
  styleUrls: ['./sales-invoice.component.css']
})
export class SalesInvoiceComponent implements OnInit {
  @ViewChild('closebutton',{static:false}) closebutton;
  @ViewChild(ItemcomponentComponent,{static:false}) itemComponent: ItemcomponentComponent;
  @ViewChild('qty',{static:false}) qty: ElementRef;
  @ViewChild('select',{static:false}) select: NgSelectComponent;

  public stateList: any;
  public costCenterList: any = [];
  public previewFinYear: any;
  public financialYears: any[] = [];
  public display: string;
  public disableOtp: boolean = true;
  public showOtpError: boolean = false;
  public submitted: boolean = false;
  public showCustForm = false;
  public itemList: any = [];
  public salesexecutives: any = [];
  public customers: any = [];
  public fileToUpload: File = null;
  public Items: any = [];
  public ItemImages: any = [];
  public OtherItemsData: any = {};
  public PaymentReceipts: any = [];
  public totalAmount: any = null;
  public rowData: any = [];
  public stockOutwards: any = [];
  public catagories: any = [];
  public itemsData: any = [];
  public user: any;
  public loading = false;
  public HSN: any = null;
  public IGST: any = null;
  public CGST: any = null;
  public SGST: any = null;
  public DefaultSalesDiscount: any = null;
  public itemCodes: any = [];
  public editData = '';
  public sameStateStatus: boolean;
  public searchString: any;
  public SalesSourceList: any[] = [];
  public itemsFlag: boolean = false;
  public showDirectInvoice: boolean = false;
  public showFinanceInvoice: boolean = false;
  public isDisabled = false;
  public financersList: any[] = [];
  public costCenterId = 0;
  public constCenterName = "";
  public showDownpaymentErr: boolean = false;
  public customerId: any = 0;
  public customerObject: any = {};
  public invoiceAmount: number = 0;
  public _invoiceAmount: any = 0;
  public amount: string;

  public searchNumber: string;
  public advanceItemsList: any[] = [];

  public balanceRedeemPoints: number = 0;
  public showBalanceRedeemPoints: boolean = false;
  public redeemPointsMessage: any = "";

  public isCustomerOfferEnabled: any = "";
  public isItemOfferEnabled: any = "";
  public redeemableAmount:any=0;
  public redeemableAmountColor="#B5EAAA";

  public customerOfferMsgcolors:any=[
    {status:"N",color:"warning"},
    {status:"Y",color:"green"},
  ]
  public paymentTypes: any = [
    {
      ID: 'Cash',
      Code: 'Cash'
    },
    {
      ID: 'Cheque',
      Code: 'Cheque'
    },
    {
      ID: 'DemandDraft',
      Code: 'Demand Draft'
    },
    {
      ID: 'Card',
      Code: 'Card'
    },
    {
      ID: 'Credit',
      Code: 'Credit'
    },
    {
      ID: 'CreditNote',
      Code: 'CreditNote'
    },
    {
      ID: 'OnlineTransfer',
      Code: 'OnlineTransfer'
    },
    {
      ID: 'OnAccount',
      Code: 'OnAccount'
    }
  ];

  public paymentTypeTempList: any = [
    {
      ID: 'Cash',
      Code: 'Cash'
    },
    {
      ID: 'Cheque',
      Code: 'Cheque'
    },
    {
      ID: 'DemandDraft',
      Code: 'Demand Draft'
    },
    {
      ID: 'Card',
      Code: 'Card'
    },
    {
      ID: 'Credit',
      Code: 'Credit'
    },
    {
      ID: 'CreditNote',
      Code: 'CreditNote'
    },
    {
      ID: 'OnlineTransfer',
      Code: 'OnlineTransfer'
    },
    {
      ID: 'OnAccount',
      Code: 'OnAccount'
    }    
  ];

  public suppliedLocations: any = [
    {
      ID: 'AtStores',
      Code: 'At Stores'
    },
    {
      ID: 'AtCustomerLocation',
      Code: 'At Customer Location'
    }
  ];

  public gridOptions = {
    pagination: true,
    paginationAutoPageSize: true
  };

  columnDefsAdvanceItems = [
    { headerName: 'Customer Name', field: 'CustomerName', filter: true, sortable: true, width: 250 },
    { headerName: 'Date of Advance', field: 'ReceiptDate', filter: true, sortable: true, width: 130 },
    { headerName: 'Item Name', field: 'ItemName', filter: true, sortable: true, width: 250 },
    { headerName: 'Qty', field: 'Qty', filter: true, sortable: true, width: 90, cellStyle: { textAlign: 'right' } },
  ];
  public ledgers: any = [];
  public accountName: string = "";
  public BalanceInvoiceAmount: any = 0;

  public salesInvoiceObj = {
    CustomerCostCenterID:null,
    RequestType: '',
    LocationID: '',
    OutwardNumber: '',
    OutwardType: 'SI',
    Supplier_Customer_Branch_Number: '',
    ReceiverName: '-',
    ReceiverPhone: '-',
    TransporterName: '-',
    ShippingAddress: '',
    OutwardDate: '',
    WaybillNumber: '-',
    MaterialSuppliedAt: this.suppliedLocations[0].ID,
    YearNo: null,
    EmployeeID: '',
    IGST: '',
    SGST: '',
    CGST: '',
    Freight: 0,
    OtherCharges: 0,
    TotalOutwardAmount: null,
    PlaceOfSupply: JSON.parse(localStorage.user).BranchName,
    Remarks: '',
    CreateUserID: '',
    CustomerEnteredValue: '',
    OutwardClientID: null,
    Discount: 0,
    Items: [],
    PaymentReceipts: [],
    ClosingBalance: null,
    SourceID: '',
    IsFinanceSale: true,
    FinanceLedgerID: [null],

    //Finacnce Module
    FinInvoiceAmount: '',
    InvoiceAmount:'',
    Downpayment: '',
    ProcessingFee: '',
    Interest: '',
    CardCharge: '',
    TotalReceivable: '',
    LoanAmount: '',
    SubventionInterest: '',
    SubvenentionCGST: '',
    SubvenentionSGST: '',
    DisbursementAmount: '',
    ScratchCardCount:0

  };

  public customerForm = this.fb.group({
    customerType: [null, Validators.required],
    costCenter: ['', Validators.required],
    customerPhoneNo: ['', Validators.required],
    customerName: ['', Validators.required],
    customerAddress: ['', Validators.required],
    customerState: ['Andhra Pradesh', Validators.required],
    customerGST: ['-', Validators.required],
    customerEmail: ['-', Validators.required],
    creditLimit: [''],
    creditPeriod: [''],
    otp: [''],
    enableWalletAmount:['N', Validators.required],
    dob: [null],
    maritalStatus:['Y'],
    weddingAnniversary: [null]
  });

  public customerEnableOfferForm = this.fb.group({
    customerName: ['', Validators.required],
    customerPhoneNo: ['', Validators.required],
    customerEmail: ['', Validators.required],
    enableWalletAmount:['Y', Validators.required],
    dob: [null],
    maritalStatus:['Y'],
    weddingAnniversary: [null]
  });
  //customerEnableOfferForm

  salesInvoiceForm = this.fb.group({
    CustomerCostCenterID:[null],
    SearchString: [null, Validators.required],
    Name: '',
    Address: '',
    TransporterName: ['-', Validators.required],
    OutwardDate: ['', Validators.required],
    WaybillNumber: ['-', Validators.required],
    MaterialSuppliedAt: [this.suppliedLocations[0].ID, Validators.required],
    ReceiverName: ['-', Validators.required],
    ReceiverPhone: ['-', Validators.required],
    EmployeeID: [null, Validators.required],
    CreateUserID: '',
    ItemID: null,
    ItemName: '',
    ItemClientID: null,
    DeliveryQty: null,
    UnitPrice: [null, Validators.pattern('^[0-9]*\.[0-9]{2}$')],
    UnitPriceTax: null,
    Amount: null,
    BarCode: null,
    BatchSlNo: null,
    BatchBalance: null,
    Items: [],
    PaymentType: ['Cash'],
    AccountID: [1],
    ChequeNumber: '',
    BankName: '',
    PaymentAmount: '',
    PaymentReceipts: [],
    PlaceOfSupply: [JSON.parse(localStorage.user).BranchName, Validators.required],
    Remarks: '',
    subTotal: null,
    IGST: null,
    CGST: null,
    SGST: null,
    Discount: 0,
    Freight: [0, Validators.required],
    OtherCharges: [0, Validators.required],
    TotalOutwardAmount: null,
    CategorySearch: null,
    ItemSearch: null,
    SourceID: [null, Validators.required],

    //Finacnce Module
    IsFinanceSale: true,
    FinanceLedgerID: [null],
    // PaymentType: null,
    FinInvoiceAmount: '',
    Downpayment: '',
    ProcessingFee: '',
    Interest: '',
    CardCharge: '',
    TotalReceivable: '',
    LoanAmount: '',
    SubventionInterest: '',
    SubventionAmount:'',
    SubvenentionCGST: '',
    SubvenentionSGST: '',
    DisbursementAmount: '',
    ScratchCardCount:0

  });

  enableWalletList=[
    {id:"Y",name:"Yes"},
    {id:"N",name:"No"}
  ];

  maritalStatusList=[
    {id:"Y",name:"Yes"},
    {id:"N",name:"No"}
  ];

  columnDefs = [
    {
      headerName: 'Actions', field: 'actions', width: 100, cellRenderer: (params) => {
        return '<i class="fas fa-edit btn text-primary" data-action-type="edit" style="padding: 5px;"></i>' +
          '<i class="fas fa-print btn text-info" data-action-type="print" style="padding: 5px;"></i>' +
          '<i class="fas fa-trash-alt btn text-danger" data-action-type="delete" style="padding: 5px;"></i>';
      }
    },
    { headerName: 'ID', field: 'OutwardNumber', width: 70, filter: true, resizable: true },
    { headerName: 'Inv No.', field: 'OutwardClientID', width: 150, filter: true, resizable: true },
    { headerName: 'Outward Date', field: 'OutwardDate', width: 120, filter: true, resizable: true },
    { headerName: 'Customer Name', field: 'CustomerName', width: 180, filter: true, resizable: true },
    { headerName: 'CustomerID', field: 'Supplier_Customer_Branch_Number', hide: true, width: 180, filter: true, resizable: true },
    {
      headerName: 'Invoice Amount', field: 'TotalOutwardAmount', width: 80, filter: true, cellStyle: { textAlign: 'right' },
      resizable: true
    }
  ];

  customerTypeList = [
    { id: 'INDIVIDUAL', name: 'INDIVIDUAL' },
    { id: 'CORPORATE', name: 'CORPORATE' }
  ];

  public customerData = {
    RequestType: "ADD",
    CostCenter:"",
    OTP: "",
    LocationID: 0,
    CustomerID: "0",
    Name: "",
    Address: "",   
    MobilePhone: "",
    State: "",
    WorkPhone: "9999999999",
    AltMobilePhone: "0",
    BriefDescription: "",
    ContactPersonDesignation: "",
    ContactPersonName: "",
    CreateUserID: "",
    CreditLimit: "0",
    CreditPeriod: "0",
    CustomerType: "",
    EmailID: "-",
    Fax: "",
    GSTNumber: "",
    OpeningBalance: "0",
    BalanceType: "CR",
    EnableWalletAmount:"N",
    DOB: null,
    WeddingAnniversary: null
  }

  constructor(private fb: FormBuilder, private datePipe: DatePipe, private salesinvoiceService: SalesinvoiceService,
    private getStateService: SupplierService, private loginService: LoginService,
    private customerService: CustomerService, private itemService: ItemcomponentService,
    private stockReportService: StockReportsService, private salesourcesrvice: SalesSourceService,
    private excelService: ExportexcelService, private commonService: CommonService,
    private inventoryService: InventoryService) { }

  ngOnInit() {
    this.setDefaultValues();
    this.getSalesExecutives();
    this.getState();
    this.getItemsData();
    this.getSalesSource();
    this.getLedgers();
    this.getFinancers();
  }
  setDefaultValues() {
    this.user = this.loginService.getUser();
    this.previewFinYear = this.user.YearNo;
    this.Items = [];
    this.isCustomerOfferEnabled = "";
    this.isItemOfferEnabled = "";
    this.balanceRedeemPoints = 0;
    this.showDirectInvoice = true;
    this.showFinanceInvoice = false;
    this.balanceRedeemPoints = 0;
    this.showBalanceRedeemPoints = false;
    this.salesInvoiceForm.patchValue({ OutwardDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd') });
    this.customerForm.patchValue({ dob: this.datePipe.transform('1900-01-01', 'yyyy-MM-dd') });
    this.customerForm.patchValue({ weddingAnniversary: this.datePipe.transform('1900-01-01', 'yyyy-MM-dd') });
  }
  getCustOfferEnableStatus(status){
    return this.customerOfferMsgcolors.filter(item=>item.status===status)[0].color;
  }
  onCostCenterChange(event){

    if (event) {
      if (event.CostCenterID) {
        this.costCenterId = event.CostCenterID;
        this.constCenterName = event.CostCenterName;
        this.salesInvoiceForm.patchValue({CustomerCostCenterID:event.CostCenterID});       
      } else {

        this.costCenterId = 1;
        if (typeof (event) === 'object') {
          this.constCenterName = event.CostCenterName;
        } else if (typeof (event) === 'string') {
          this.constCenterName = event;
        }
      }
    }
  }

  addCostCenter(){

    let postData={
      LocationID: this.user.LocationID,
      CustomerID:this.customerId,
      CostCenterName:this.constCenterName
    };
    
    this.customerService.addCostCenter(postData).subscribe(res=>{
      if (res.status === 201 || res.status === 200) { 
        this.costCenterList=[];
        this.costCenterList = res.body;      
        let tempList = this.costCenterList.filter(costCenter => costCenter.CostCenterName === this.constCenterName);       
        let constCenter = tempList[0].CostCenterID;
        this.salesInvoiceForm.patchValue({CustomerCostCenterID:constCenter});

        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Cost center added successfully.!'
        });
      }        
    });

  }

  getCostCenter(){
    let inpuData={
      LocationID: this.user.LocationID,
      SearchID:this.customerId //CustomerID
    };
    this.customerService.getCostCenter(inpuData).subscribe(res=>{
      this.costCenterList = res.body;
    });
  }

  getFinancers() {
    let locationId = this.user.LocationID;
    this.commonService.getFinancers(locationId).subscribe(res => {
      this.financersList = [];
      let resList: any = [];
      resList = res;
      resList.forEach(element => {        
        let obj = {
          ID: element.ACTLED_ID,
          Value: element.ACTLED_NAME
        };
        this.financersList.push(obj);
      });
    });
  }

  onCustomerTypeChange(event) {
    if (event.id == "INDIVIDUAL") {
      this.isDisabled = true;
      this.customerForm.patchValue({ GSTNumber: "-" });
      this.customerForm.patchValue({ ContactPersonName: "-" });
      this.customerForm.patchValue({ ContactPersonDesignation: "-" });
      this.customerForm.patchValue({ EmailID: "-" });
    }
    else {
      this.isDisabled = false;
      this.customerForm.patchValue({ GSTNumber: "" });
      this.customerForm.patchValue({ ContactPersonName: "" });
      this.customerForm.patchValue({ ContactPersonDesignation: "" });
      this.customerForm.patchValue({ EmailID: "" });
    }
  }

  onSaleTypeChange(invoiceType) {
    this.PaymentReceipts =[];
    this.salesInvoiceForm.patchValue({ PaymentType: 'Cash' });
    this.salesInvoiceForm.patchValue({ AccountID: 1 });    
    this.salesInvoiceForm.patchValue({ FinanceLedgerID: null });

    if (invoiceType == "direct-inv") {
      this.showDirectInvoice = true;
      this.showFinanceInvoice = false;
    } else {
      this.showDirectInvoice = false;
      this.showFinanceInvoice = true;
    }
  }

  getFinanceTotalAmt(){    
    this.showDownpaymentErr=false;
    let downpayment = 0;
    let porcessingFee =0;
    let intrest = 0;
    let cardCharge = 0;
    let totalReceivalble = 0;
    let loanAmount =0;
    let invoiceAmount = 0;
    let downPayment = 0;
    
    invoiceAmount = this.salesInvoiceForm.value.TotalOutwardAmount;
    downPayment = this.salesInvoiceForm.value.Downpayment;
    if(Number(downPayment)>Number(invoiceAmount)){      
      this.salesInvoiceForm.patchValue({ Downpayment: '' });
      this.showDownpaymentErr=true;
      return;
    }
    
    loanAmount= Number(invoiceAmount) -Number(downPayment);    
    this.salesInvoiceForm.patchValue({ LoanAmount: loanAmount });

    downpayment = this.salesInvoiceForm.value.Downpayment;
    porcessingFee = this.salesInvoiceForm.value.ProcessingFee;
    intrest = this.salesInvoiceForm.value.Interest;
    cardCharge = this.salesInvoiceForm.value.CardCharge;
    totalReceivalble = Number(downpayment)+Number(porcessingFee)+Number(intrest)+Number(cardCharge);        
    this.salesInvoiceForm.patchValue({ TotalReceivable: totalReceivalble });

  }

  getFinanceDisbursmenAmt(){    
    let processingFee = 0;    
    let intrest = 0;    
    let subventionInterest =0;
    let loanAmount =0;
    let deductableAmount =0;
    let disbursementAmount:any =0;
    let subvenentionCGST:any=0;
    let subvenentionSGST:any=0;
    let subvenentionAmount:any=0;
    let cardCharge = 0;

    processingFee = this.salesInvoiceForm.value.ProcessingFee;    
    intrest = this.salesInvoiceForm.value.Interest;
    subventionInterest = this.salesInvoiceForm.value.SubventionInterest;
    
    //subvenentionCGST = (subventionInterest / 109) * 100;
    //subvenentionSGST = (subventionInterest / 109) * 100;

    //subvenentionCGST = ((9*subventionInterest)/100);
    //subvenentionSGST = ((9*subventionInterest)/100);
    subvenentionCGST = ((subventionInterest*9)/118);
    subvenentionSGST = ((subventionInterest*9)/118);

    //subvenentionCGST = subventionInterest - subvenentionCGST;
    //subvenentionSGST = subventionInterest - subvenentionSGST;

    subvenentionAmount = (subventionInterest -(subvenentionSGST+subvenentionCGST)).toFixed(2);
    this.salesInvoiceForm.patchValue({ SubventionAmount: subvenentionAmount });

    cardCharge = this.salesInvoiceForm.value.CardCharge;
    loanAmount= this.salesInvoiceForm.value.LoanAmount;
    deductableAmount = Number(processingFee)+Number(intrest)+Number(subventionInterest)+Number(cardCharge);   
    disbursementAmount = (Number(loanAmount) - Number(deductableAmount)).toFixed(2);
    subvenentionCGST= subvenentionCGST.toFixed(2);
    subvenentionSGST= subvenentionSGST.toFixed(2);    
    this.salesInvoiceForm.patchValue({ SubvenentionCGST: subvenentionCGST }); 
    this.salesInvoiceForm.patchValue({ SubvenentionSGST: subvenentionSGST }); 
    this.salesInvoiceForm.patchValue({ DisbursementAmount: disbursementAmount });
    
  }  

  clearPreviewData() {
    this.rowData = [];
    this.previewFinYear = this.user.YearNo;
  }

  getFinancialYears() {
    const locationCode = this.user.LocationID;
    this.loginService.getFinancialYears(locationCode).subscribe(res => {
      this.itemList = [];
      let resData = {};
      if (res) {
        res.forEach(item => {
          const fromYear = new Date(item.YearFrom);
          const toYear = new Date(item.YearTo);
          const finYear = fromYear.getFullYear() + ' - ' + toYear.getFullYear();
          resData = {
            ID: item.YearNo,
            Code: finYear
          };
          this.itemList.push(resData);
        });
        this.financialYears = this.itemList;
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  customerKeyDownFn(searchString) {
    if (searchString.length >= 5) {
      // this.getCustomerSearchList();
      this.getCustomersBySearch(searchString, "add");
    }
  }

  exportExel() {
    let obj = {
      CustomerName: "",
      OutwardClientID: "",
      OutwardDate: "",
      OutwardNumber: "",
      Supplier_Customer_Branch_Number: "",
      TotalOutwardAmount: ""
    }
    this.rowData.push(obj);
    obj = {
      CustomerName: "Total Amount: ",
      OutwardClientID: "",
      OutwardDate: "",
      OutwardNumber: "",
      Supplier_Customer_Branch_Number: "",
      TotalOutwardAmount: this._invoiceAmount
    }
    this.rowData.push(obj);
    this.excelService.exportAsExcelFile(this.rowData, 'sale_invoice_report');
  }

  getState() {
    let obj = { SearchString: "India" };
    this.getStateService.getState(obj).subscribe(res => {
      this.stateList = res.body;
    });
  }

  setCustomerDefaultValues() {

    this.customerForm = this.fb.group({
      customerType: [null, Validators.required],
      customerPhoneNo: ['', Validators.required],
      customerName: ['', Validators.required],
      customerAddress: [this.user.Locations[0].Name, Validators.required],
      customerState: [this.user.Locations[0].StateName, Validators.required],
      customerGST: ['-', Validators.required],
      customerEmail: ['-', Validators.required],
      creditLimit: [''],
      costCenter: ['NO COST CENTER'],
      creditPeriod: [''],
      otp: [''],
      enableWalletAmount:['N', Validators.required],
      dob: this.datePipe.transform('1900-01-01', 'yyyy-MM-dd'),
      maritalStatus:['Y'],
      weddingAnniversary: this.datePipe.transform('1900-01-01', 'yyyy-MM-dd')
    });
  }

  saveCustomer() {
    if (!this.customerForm.valid) {
      return;
    }
    let creditLimit = this.customerForm.value.creditLimit;
    let creditPeriod = this.customerForm.value.creditPeriod;
    if (creditLimit == "" && creditPeriod != "") {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Please enter credit limit'
      });
      return;
    }
    if (creditLimit != "" && creditPeriod == "") {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Please enter credit period'
      });
      return;
    }
    this.customerData.RequestType = "ADD";
    this.customerData.CustomerType = this.customerForm.value.customerType;
    this.customerData.CostCenter = this.customerForm.value.costCenter;
    this.customerData.MobilePhone = this.customerForm.value.customerPhoneNo;
    this.customerData.LocationID = this.user.LocationID;
    this.customerData.GSTNumber = '';
    this.customerData.Name = this.customerForm.value.customerName;
    this.customerData.Address = this.customerForm.value.customerAddress;
    this.customerData.State = this.customerForm.value.customerState;
    this.customerData.GSTNumber = this.customerForm.value.customerGST;
    
    this.customerData.CreditLimit = this.customerForm.value.creditLimit;
    this.customerData.CreditPeriod = this.customerForm.value.creditPeriod;
    this.customerData.CostCenter = this.customerForm.value.costCenter;

    this.customerData.EnableWalletAmount = this.customerForm.value.enableWalletAmount;
    this.customerData.EmailID = this.customerForm.value.customerEmail;
    this.customerData.DOB = this.datePipe.transform(this.customerForm.value.dob,'dd-MMM-yyyy');
    this.customerData.WeddingAnniversary = this.datePipe.transform(this.customerForm.value.weddingAnniversary,'dd-MMM-yyyy');

    this.customerData.CreateUserID = this.user.EmployeeCode;
    this.customerService.createCustomer(this.customerData).subscribe(res => {
      if (res.status === 201 || res.status === 200) {
        if (creditLimit == "" && creditPeriod == "") {
          this.getCustomerSearchList();
          this.setCustomerDefaultValues();
        }
        this.disableOtp = false;
        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Customer created successfully'
        });
      }
    });
  }

  makeCustomerActive() {
    this.showOtpError = false;
    this.submitted = true;
    if (this.customerForm.invalid) {
      return;
    }
    if (this.customerForm.value.otp == "" || this.customerForm.value.otp == null || this.customerForm.value.otp == undefined) {
      this.showOtpError = true;
      return;
    }
    this.customerData.RequestType = "OTP";
    this.customerData.OTP = this.customerForm.value.otp;
    this.customerService.createCustomer(this.customerData).subscribe(res => {
      if (res.status === 201) {
        this.getCustomerSearchList();
        this.setCustomerDefaultValues();
        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Customer activated successfully'
        });
      }
    });
  }

  onRowClicked(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const actionType = e.event.target.getAttribute('data-action-type');

      switch (actionType) {
        case 'edit':
          return this.getSalesInvoiceData(data);
        case 'delete':
          return this.deleteSaleInvoice(data);
        case 'print':
          return this.printSaleInvoice(data);
      }
    }
  }

  printSaleInvoice(data: any) {
    let inputData = {
      ID: data.OutwardNumber,
      LocationID: this.user.LocationID
    }
    this.salesinvoiceService.printSaleInvoice(inputData).subscribe(res => {
      if (res.body) {
        const mywindow = window.open();
        mywindow.document.write(res.body.InvoiceHtml);
        mywindow.print();
      }
    });
  }

  getSalesInvoiceData(data: any) {
    let inputData = {
      ID: data.OutwardNumber,
      LocationID: this.user.LocationID
    }
    this.salesinvoiceService.getSalesInvoice(inputData).subscribe(res => {

      this.customerId = data.Supplier_Customer_Branch_Number;
      this.getCostCenter();
      this.editData = 'UPDATE';
      this.sameStateStatus = res.body.SameStateStatus;
      this.salesInvoiceObj.OutwardNumber = data.OutwardNumber;
      this.salesInvoiceObj.OutwardClientID = data.OutwardClientID;
      this.salesInvoiceObj.Supplier_Customer_Branch_Number = data.Supplier_Customer_Branch_Number;
      this.salesInvoiceObj.ClosingBalance = data.ClosingBalance;
      this.salesInvoiceForm.patchValue({ OutwardDate: this.datePipe.transform(res.body.OutwardDate, 'yyyy-MM-dd') });
      this.salesInvoiceForm.patchValue({ ReceiverName: res.body.ReceiverName });
      this.salesInvoiceForm.patchValue({ ReceiverPhone: res.body.ReceiverPhone });
      this.salesInvoiceForm.patchValue({ TransporterName: res.body.TransporterName });
      this.salesInvoiceForm.patchValue({ WaybillNumber: res.body.WaybillNumber });
      this.salesInvoiceForm.patchValue({ EmployeeID: res.body.EmployeeID });
      this.salesInvoiceForm.patchValue({ Freight: res.body.Freight.toFixed(2) });
      this.salesInvoiceForm.patchValue({ OtherCharges: res.body.OtherCharges.toFixed(2) });
      this.salesInvoiceForm.patchValue({ PlaceOfSupply: res.body.PlaceOfSupply });
      this.salesInvoiceForm.patchValue({ Remarks: res.body.Remarks });
      this.salesInvoiceForm.patchValue({ SearchString: res.body.CustomerEnteredValue });
      this.salesInvoiceForm.patchValue({ Name: res.body.CustomerName });
      this.salesInvoiceForm.patchValue({ Address: res.body.Address });
      this.salesInvoiceForm.patchValue({ Discount: res.body.Discount });
      this.salesInvoiceForm.patchValue({ MaterialSuppliedAt: res.body.MaterialSuppliedAt });
      this.salesInvoiceForm.patchValue({ SourceID: res.body.SourceID });

      this.salesInvoiceForm.patchValue({ CustomerCostCenterID: res.body.CustomerCostCenterID });
      this.salesInvoiceObj.CustomerCostCenterID = res.body.CustomerCostCenterID;

      this.showDirectInvoice = true;
      this.showFinanceInvoice = false;
     
      if(res.body.IsFinanceSale == true){
        this.showDirectInvoice = false;
        this.showFinanceInvoice = true;

        this.salesInvoiceForm.patchValue({ IsFinanceSale: res.body.IsFinanceSale });
        this.salesInvoiceForm.patchValue({ FinanceLedgerID: res.body.FinanceLedgerID });
        this.salesInvoiceForm.patchValue({ Downpayment: res.body.Downpayment });
        this.salesInvoiceForm.patchValue({ Interest: res.body.Interest });
        this.salesInvoiceForm.patchValue({ LoanAmount: res.body.LoanAmount });             
        this.salesInvoiceForm.patchValue({ CardCharge: res.body.CardCharge });     
        this.salesInvoiceForm.patchValue({ DisbursementAmount: res.body.DisbursementAmount });        
        this.salesInvoiceForm.patchValue({ SubventionInterest: res.body.SubventionInterest });        
        this.salesInvoiceForm.patchValue({ SubvenentionCGST: res.body.SubvenentionCGST });
        this.salesInvoiceForm.patchValue({ SubvenentionSGST: res.body.SubvenentionSGST });
        this.salesInvoiceForm.patchValue({ ProcessingFee: res.body.ProcessingFee });        
        this.salesInvoiceForm.patchValue({ TotalReceivable: res.body.TotalReceivable });
        
        let downpayment = this.salesInvoiceForm.value.Downpayment;
        let porcessingFee = this.salesInvoiceForm.value.ProcessingFee;
        let intrest = this.salesInvoiceForm.value.Interest;
        let cardCharge = this.salesInvoiceForm.value.CardCharge;
        let totalReceivalble = Number(downpayment)+Number(porcessingFee)+Number(intrest)+Number(cardCharge);        
        this.salesInvoiceForm.patchValue({ TotalReceivable: totalReceivalble });
        
        let accountId = res.body.PaymentReceipts[0].AccountID;
        let paymentType = res.body.PaymentReceipts[0].PaymentType;

        this.salesInvoiceForm.patchValue({ AccountID: accountId });
        this.salesInvoiceForm.patchValue({ PaymentType: paymentType });

        let subvenentionAmount =0;
        let subvenentionCGST = 0;
        let subvenentionSGST = 0;
        let subventionInterest =0;
        subvenentionCGST = res.body.SubvenentionCGST;
        subvenentionSGST = res.body.SubvenentionSGST;
        subventionInterest = res.body.SubventionInterest;
        subvenentionAmount = (subventionInterest - (subvenentionCGST+subvenentionSGST));
        this.salesInvoiceForm.patchValue({ SubventionAmount: subvenentionAmount });

      }

      res.body.Items.map(item => {
        item.UnitPrice = item.UnitPrice.toFixed(2);
      });

      this.Items = res.body.Items;
      this.PaymentReceipts = res.body.PaymentReceipts;
      this.calulateSubTotalAndOutwardAmount();
      let searchString: string = res.body.CustomerMobile;
      this.getCustomersBySearch(searchString, "edit");
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });

  }

  deleteSaleInvoice(data: any) {
    this.editData = 'DELETE';
    this.salesInvoiceObj.OutwardNumber = data.OutwardNumber;
    this.salesInvoiceObj.OutwardClientID = data.OutwardClientID;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.submitSalesInvoice();
      }
    });
  }

  // get all stock inwards
  getSaleInvoices() {
    this.rowData = [];
    this.stockOutwards = [];
    this.invoiceAmount = 0;
    const inputData = {
      LocationID: this.user.LocationID,
      YearNo: this.previewFinYear
    };
    this.salesinvoiceService.getSaleInvoices(inputData).subscribe(async res => {
      if (res.length > 0) {
        res.forEach(stockInward => {
          this.invoiceAmount = this.invoiceAmount + Number(stockInward.TotalOutwardAmount);
          this._invoiceAmount = this.invoiceAmount
          // .toLocaleString('en-IN', { style: 'currency', currency:
          // "INR", minimumFractionDigits: 2, maximumFractionDigits: 2 });

          this.stockOutwards.push({
            OutwardNumber: stockInward.OutwardNumber, OutwardClientID: stockInward.OutwardClientID,
            Supplier_Customer_Branch_Number: stockInward.Supplier_Customer_Branch_Number,
            OutwardDate: this.datePipe.transform(stockInward.OutwardDate, 'dd-MMM-yyyy'),
            CustomerName: stockInward.CustomerName,
            TotalOutwardAmount: stockInward.TotalOutwardAmount
            //.toLocaleString('en-IN', { style: 'currency', currency:
            // "INR", minimumFractionDigits: 2, maximumFractionDigits: 2 })
          });
        });
      }
      this.rowData = this.stockOutwards;
      await this.getFinancialYears();

    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }  

  setCustomerEnableOfferForm(){

    if(this.customerId==0){
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'Please select customer'
      });
      return;
    }else{
      this.customerEnableOfferForm.patchValue({customerName:this.customerObject.Name});
      this.customerEnableOfferForm.patchValue({customerPhoneNo:this.customerObject.MobilePhone});
      this.customerEnableOfferForm.patchValue({customerEmail:this.customerObject.EmailID});
      this.customerEnableOfferForm.patchValue({dob:this.datePipe.transform(this.customerObject.DOB,'yyyy-MM-dd')});
      this.customerEnableOfferForm.patchValue({weddingAnniversary:this.datePipe.transform(this.customerObject.WeddingAnniversary,'yyyy-MM-dd')});
    }

  }

  enableOrDisableCustomerOffer (walletFlag){

    if(this.customerId==0){
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'Please select customer'
      });
      return;
    }

    let postData={
      LocationID :this.user.LocationID,
      CustomerID :this.customerId,
      EmailID:this.customerEnableOfferForm.value.customerEmail,
      DOB: this.datePipe.transform(this.customerEnableOfferForm.value.dob,'dd-MMM-yyyy'),
      WeddingAnniversary:this.datePipe.transform(this.customerEnableOfferForm.value.weddingAnniversary,'dd-MMM-yyyy'),
      EnableWalletAmount:walletFlag,
      CreateUserID:this.user.EmployeeCode
    };
    this.salesinvoiceService.updteCustomerWalletFlag(postData).subscribe(res=>{
      if(res.status==200 || res.status==201){
        let updateMsg= "";
        this.isCustomerOfferEnabled=walletFlag;
        if(walletFlag=="Y"){
          updateMsg="enabled";
        }else{
          updateMsg="disabled";
        }
        Swal.fire({
          type: 'success',
          title: 'Success',
          text: 'Customer wallet '+updateMsg+' successfully'
        });
      }
    });
  }

  // to get CustomerDetails
  getCustomerDetails() {

    this.isCustomerOfferEnabled = "";
    this.redeemableAmount=0;
    this.customerObject = {};
    
    if (this.salesInvoiceForm.value.SearchString) {
      const inputObj = {
        SearchString: this.salesInvoiceForm.value.SearchString,
        LocationID: this.user.LocationID
      };
      this.salesinvoiceService.getCustomerDetails(inputObj).subscribe(res => {
        if (res.status === 200 && res.body) {

          //For cost center
          this.customerId = res.body.CustomerID;
          this.isCustomerOfferEnabled = res.body.EnableWalletAmount;

          this.customerObject = res.body;
          console.log("", this.customerObject);
          this.getCostCenter();

          if (res.body.BalanceToPay < 0) {
            Swal.fire({
              title: 'Are you sure?',
              text: "Customer has Debit(Dr) balance for Rs. " + res.body.BalanceToPay + "/- do u want to continue",
              showCancelButton: true,
              confirmButtonText: 'Yes, Go ahead',
              cancelButtonText: 'No, cancel!',
              confirmButtonColor: '#007bff',
              cancelButtonColor: '#dc3545',
            }).then((result) => {
              if (result.value) {
                this.sameStateStatus = res.body.SameStateStatus;
                const address = res.body.Address + ',' + res.body.State;
                this.salesInvoiceForm.patchValue({ Name: res.body.Name });
                this.salesInvoiceForm.patchValue({ Address: address });
                this.salesInvoiceObj.Supplier_Customer_Branch_Number = res.body.CustomerID;
                this.salesInvoiceObj.ClosingBalance = res.body.ClosingBalance;

                if (res.body.Items.length > 0) {
                  this.getCartItemsAddToItems(res.body.Items);
                }
              } else {
                this.salesInvoiceForm.patchValue({ SearchString: null });
              }
            });
          } else {
            this.sameStateStatus = res.body.SameStateStatus;
            const address = res.body.Address + ',' + res.body.State;
            this.salesInvoiceForm.patchValue({ Name: res.body.Name });
            this.salesInvoiceForm.patchValue({ Address: address });
            this.salesInvoiceObj.Supplier_Customer_Branch_Number = res.body.CustomerID;
            this.salesInvoiceObj.ClosingBalance = res.body.ClosingBalance;
            if (res.body.Items.length > 0) {
              this.getCartItemsAddToItems(res.body.Items);
            }
          }
          this.balanceRedeemPoints = res.body.RedeemPoint;
          this.showBalanceRedeemPoints = true;
        } else {
          Swal.fire({
            type: 'error',
            title: 'Error',
            text: 'Invalid Details, Please Try with Valid GST/ Phone Number'
          });
          this.salesInvoiceForm.patchValue({ SearchString: null });
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    } else {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Please Enter Phone/GST No'
      });
      this.salesInvoiceForm.patchValue({ Address: '' });
      this.salesInvoiceForm.patchValue({ Name: '' });
    }

  }

  // to get SalesExecutives
  getSalesExecutives() {
    const locationId = this.user.LocationID;
    this.salesinvoiceService.getSalesExecutives(locationId).subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.EmployeeID,
          Code: item.FirstName
        };
        this.itemList.push(resData);
      });
      this.salesexecutives = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  getCustomersBySearch(searchString, callFrom) {
    let inputData = {
      SearchString: searchString,
      LocationID: this.user.LocationID
    }
    this.commonService.getCustomerListBySearch(inputData).subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.body.forEach(customer => {
        resData = {
          ID: customer.MobilePhone + '-' + customer.Name,
          Code: customer.MobilePhone + '-' + customer.Name
        };
        this.itemList.push(resData);
      });
      this.customers = this.itemList;

      if (callFrom == "edit") {
        this.getCustomerDetails();
      }

      if (callFrom != "edit") {
        if (this.customerForm.value.customerPhoneNo != "" && this.customerForm.value.customerPhoneNo != null && this.customerForm.value.customerPhoneNo != undefined) {

          this.salesInvoiceForm.value.SearchString = this.customerForm.value.customerPhoneNo;
          this.searchString = this.customerForm.value.customerPhoneNo;
          this.customerForm = this.fb.group({
            customerPhoneNo: [null, Validators.required],
            customerName: ['', Validators.required],
            customerAddress: ['', Validators.required],
            customerState: [null, Validators.required],
            customerEmail: ['', Validators.required],
            creditLimit: [''],
            costCenter: ['NO COST CENTER'],
            customerType: [''],
            creditPeriod: [''],
            otp: [''],
          });
        }
      }

    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  getCustomerSearchList() {

    this.salesinvoiceService.getCustomerSearchList(this.user.LocationID).subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.SearchText.forEach(customer => {
        resData = {
          ID: customer,
          Code: customer
        };
        this.itemList.push(resData);
      });
      this.customers = this.itemList;
      if (this.customerForm.value.customerPhoneNo != "" && this.customerForm.value.customerPhoneNo != null && this.customerForm.value.customerPhoneNo != undefined) {

        this.salesInvoiceForm.value.SearchString = this.customerForm.value.customerPhoneNo;
        this.searchString = this.customerForm.value.customerPhoneNo;
        this.customerForm = this.fb.group({
          customerType: [null, Validators.required],
          customerPhoneNo: ['', Validators.required],
          customerName: ['', Validators.required],
          customerAddress: ['', Validators.required],
          customerState: [null, Validators.required],
          customerEmail: ['', Validators.required],
          creditLimit: [''],
          creditPeriod: [''],
          otp: [''],
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });

  }

  getGstDetails(inputData) {
    this.advanceItemsList = [];
    this.salesinvoiceService.getItem(inputData).subscribe(res => {
      if (res.status === 200 && res.body) {
        this.OtherItemsData.HSN = res.body.HSN;
        this.OtherItemsData.IGST = (res.body.IGST) ? res.body.IGST : 0;
        this.OtherItemsData.CGST = (res.body.CGST) ? res.body.CGST : 0;
        this.OtherItemsData.SGST = (res.body.SGST) ? res.body.SGST : 0;
        this.OtherItemsData.DefaultSalesDiscount = (res.body.DefaultSalesDiscount) ? res.body.DefaultSalesDiscount : 0;
        this.advanceItemsList = res.body.AdvanceItemDetail;
        this.advanceItemsList.map(element => element.ReceiptDate = this.datePipe.transform(element.ReceiptDate, "dd-MMM-yyyy"));
        if (this.advanceItemsList != null && this.advanceItemsList != undefined) {
          if (this.advanceItemsList.length > 0) {
            this.display = 'block';
          }
        }
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    });
  }

  getModalData() {
    this.salesInvoiceForm.patchValue({ ItemName: this.itemComponent.itemForm.value.ItemName });
    this.salesInvoiceForm.patchValue({ ItemID: this.itemComponent.itemForm.value.ItemID });
    this.salesInvoiceForm.patchValue({ ItemClientID: this.itemComponent.itemForm.value.ItemClientID });
    this.salesInvoiceForm.patchValue({ UnitPrice: this.itemComponent.itemForm.value.SalePrice });
    this.IGST = this.itemComponent.itemForm.value.IGST;
    this.CGST = this.itemComponent.itemForm.value.CGST;
    this.SGST = this.itemComponent.itemForm.value.SGST;
    this.DefaultSalesDiscount = this.itemComponent.itemForm.value.DefaultSalesDiscount;
    const inputData = {
      ItemID: this.itemComponent.itemForm.value.ItemID,
      SupplierNumber: this.salesInvoiceObj.Supplier_Customer_Branch_Number,
      LocationID: this.user.LocationID
    };
    this.getGstDetails(inputData);
    this.itemComponent.clearModalData();
    this.closebutton.nativeElement.click();
  }

  getBarcodeInfo(event) {
    const inputData = {
      LocationID: this.user.LocationID,
      ItemCode_ItemName: event.target.value,
      CustomerID: this.salesInvoiceForm.value.Supplier_Customer_Branch_Number
    };
    this.salesinvoiceService.getItemDetails(inputData).subscribe(res => {
      if (res.body) {
        let UnitPriceTax = 0;
        this.salesInvoiceForm.patchValue({ ItemName: res.body.ItemName });
        this.salesInvoiceForm.patchValue({ ItemID: res.body.ItemID });
        this.salesInvoiceForm.patchValue({ ItemClientID: res.body.ItemClientID });
        this.salesInvoiceForm.patchValue({ UnitPrice: res.body.SalePrice.toFixed(2) });
        this.salesInvoiceForm.patchValue({ UnitPriceTax: res.body.SalePrice.toFixed(2) });
        this.salesInvoiceForm.patchValue({ BarCode: event.target.value });
        this.salesInvoiceForm.patchValue({ BatchSlNo: res.body.BatchSlNo });
        this.salesInvoiceForm.patchValue({ BatchBalance: res.body.BatchBalance });
        this.ItemImages = res.body.FilePath;
        this.IGST = res.body.IGST;
        this.CGST = res.body.CGST;
        this.SGST = res.body.SGST;
        this.DefaultSalesDiscount = res.body.DefaultSalesDiscount;
        const inputDataForGst = {
          ItemID: res.body.ItemID,
          SupplierNumber: this.salesInvoiceObj.Supplier_Customer_Branch_Number,
          LocationID: this.user.LocationID
        };
        this.getGstDetails(inputDataForGst);
        if (this.sameStateStatus) {
          UnitPriceTax = res.body.SalePrice / (100 + Number(Number(this.CGST) + Number(this.SGST))) * 100;
        } else {
          UnitPriceTax = res.body.SalePrice / (100 + Number(this.IGST)) * 100;
        }
        this.salesInvoiceForm.patchValue({ UnitPriceTax: UnitPriceTax.toFixed(2) });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  resetModalForm() {
    this.itemComponent.clearModalData();
  }

  getCartItemsAddToItems(items) {
    this.Items = [];
    let UnitPriceTax = 0;
    items.forEach((item) => {
      const repeatObj = {
        ItemID: null,
        ItemName: '',
        ItemClientID: '',
        HSN: '',
        IGST: null,
        IGST_Amount: null,
        CGST: null,
        CGST_Amount: null,
        SGST: null,
        SGST_Amount: null,
        DeliveryQty: null,
        UnitPrice: null,
        UnitPriceTax: null,
        Amount: null,
        DefaultSalesDiscount: null,
        Discount_Amount: null,
        BarCode: '',
        BatchSlNo: ''
      };
      repeatObj.ItemID = item.ItemID;
      repeatObj.ItemName = item.ItemName;
      repeatObj.ItemClientID = item.ItemClientID;
      repeatObj.HSN = item.HSN;
      repeatObj.IGST = item.IGST;
      repeatObj.IGST_Amount = item.IGST_Amount.toFixed(2);
      repeatObj.CGST = item.CGST;
      repeatObj.CGST_Amount = item.CGST_Amount.toFixed(2);
      repeatObj.SGST = item.SGST;
      repeatObj.SGST_Amount = item.SGST_Amount.toFixed(2);
      repeatObj.DeliveryQty = item.DeliveryQty;
      repeatObj.UnitPrice = item.UnitPrice;
      if (this.sameStateStatus) {
        const UnitPriceTaxSCGST = item.UnitPrice / (100 + Number(Number(this.CGST) + Number(this.SGST))) * 100;
        UnitPriceTax = Number(UnitPriceTaxSCGST);
      } else {
        const UnitPriceTaxIGST = item.UnitPrice / (100 + Number(this.IGST)) * 100;
        UnitPriceTax = Number(UnitPriceTaxIGST);
      }
      repeatObj.UnitPriceTax = UnitPriceTax.toFixed(2);
      repeatObj.Amount = item.Amount;
      repeatObj.DefaultSalesDiscount = item.DefaultSalesDiscount;
      repeatObj.Discount_Amount = item.Discount_Amount;
      repeatObj.BarCode = item.BarCode;
      repeatObj.BatchSlNo = item.BatchSlNo;
      this.Items.push(repeatObj);
    });
    this.calulateSubTotalAndOutwardAmount();
    this.clearSubFormItemsData();
  }

  // Add Sale Invoice Items
  addToList() {
    //********Validating to not to add duplicate items to the list*******//
    let itemId = this.salesInvoiceForm.value.ItemID;
    let itemContainingList = this.Items.filter(item => item.ItemID == itemId);
    if (itemContainingList.length > 0) {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'The item already added to the invoice items list'
      });
      return;
    }
//********Validation end*******//
    if (this.isCustomerOfferEnabled == 'Y' && this.isItemOfferEnabled =='Y' && this.balanceRedeemPoints>0 ) {

      this.paymentTypes = [];
      this.paymentTypeTempList.forEach(element => {
        let obj = {
          ID: element.ID,
          Code: element.Code
        }
        this.paymentTypes.push(obj);
      });

      let obj = {
        ID: 'RedeemPoint',
        Code: 'RedeemPoint'
      }
      this.paymentTypes.push(obj);
    }

    const repeatObj = {
      ItemID: null,
      ItemName: '',
      ItemClientID: '',
      HSN: '',
      IGST: null,
      IGST_Amount: null,
      CGST: null,
      CGST_Amount: null,
      SGST: null,
      SGST_Amount: null,
      DeliveryQty: null,
      UnitPrice: null,
      UnitPriceTax: null,
      Amount: null,
      DefaultSalesDiscount: null,
      Discount_Amount: null,
      BarCode: '',
      BatchSlNo: '',
      EnableWalletAmount:this.isItemOfferEnabled,
      Color:"white"
    };
    if (this.sameStateStatus === undefined) {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Please Select Customer Phone or GST'
      });
    } else {

      //Commented to allow negative values on stock as pre Naren rquest
      this.salesInvoiceForm.value.BatchBalance = 1000;

      // if (this.salesInvoiceForm.value.DeliveryQty > this.salesInvoiceForm.value.BatchBalance) {
      //   Swal.fire({
      //     type: 'warning',
      //     title: 'Warning',
      //     text: 'Delivery Quantiity Cannot exceed Batch Balance(' + this.salesInvoiceForm.value.BatchBalance + ')'
      //   });
      // } else {

      if (this.salesInvoiceForm.value.ItemName && this.salesInvoiceForm.value.DeliveryQty && this.salesInvoiceForm.value.UnitPrice) {
        repeatObj.ItemID = this.salesInvoiceForm.value.ItemID;
        repeatObj.ItemName = this.salesInvoiceForm.value.ItemName;
        repeatObj.ItemClientID = this.salesInvoiceForm.value.ItemClientID;
        repeatObj.HSN = this.OtherItemsData.HSN;
        repeatObj.IGST = this.OtherItemsData.IGST;
        repeatObj.CGST = this.OtherItemsData.CGST;
        repeatObj.SGST = this.OtherItemsData.SGST;
        repeatObj.DeliveryQty = this.salesInvoiceForm.value.DeliveryQty;
        repeatObj.UnitPrice = this.salesInvoiceForm.value.UnitPrice;
        
        if (this.sameStateStatus) {
          let UnitPriceTaxSCGST = this.salesInvoiceForm.value.UnitPrice / (100 + Number(Number(this.OtherItemsData.CGST) +
            Number(this.OtherItemsData.SGST))) * 100;
          // UnitPriceTaxSCGST = Number(UnitPriceTaxSCGST);
          repeatObj.UnitPriceTax = UnitPriceTaxSCGST.toFixed(2);
        } else {
          let UnitPriceTaxIGST = this.salesInvoiceForm.value.UnitPrice / (100 + Number(this.OtherItemsData.IGST)) * 100;
          // UnitPriceTaxIGST = Number(UnitPriceTaxIGST);
          repeatObj.UnitPriceTax = UnitPriceTaxIGST.toFixed(2);
        }
        repeatObj.Amount = this.salesInvoiceForm.value.Amount;
        repeatObj.DefaultSalesDiscount = this.OtherItemsData.DefaultSalesDiscount;
        repeatObj.BarCode = this.salesInvoiceForm.value.BarCode;
        repeatObj.BatchSlNo = this.salesInvoiceForm.value.BatchSlNo;

        if(this.isItemOfferEnabled=="Y"){
          repeatObj.Color = "#B5EAAA"
          this.redeemableAmount = Number(this.redeemableAmount) + Number(repeatObj.UnitPriceTax);
          this.redeemableAmount=this.redeemableAmount.toFixed(2);
        }

        this.Items.push(repeatObj);

        this.calulateSubTotalAndOutwardAmount();

        this.clearSubFormItemsData();
        this.select.focus();
        //filterInput.nativeElement.focus();

      } else {
        Swal.fire({
          type: 'warning',
          title: 'Warning',
          text: 'Please Select Item Code and Enter Quantity Details'
        });
      }

      //}
    }
  }

  calculateTotalAmount() {
    let unitPrice = 0;
    const qty = (this.salesInvoiceForm.value.DeliveryQty) ? this.salesInvoiceForm.value.DeliveryQty : 0;
    if (this.sameStateStatus) {
      const UnitPriceTaxSCGST = this.salesInvoiceForm.value.UnitPrice / (100 + Number(Number(this.CGST) + Number(this.SGST))) * 100;
      unitPrice = Number(UnitPriceTaxSCGST);
    } else {
      const UnitPriceTaxIGST = this.salesInvoiceForm.value.UnitPrice / (100 + Number(this.IGST)) * 100;
      unitPrice = Number(UnitPriceTaxIGST);
    }
    this.salesInvoiceForm.patchValue({ UnitPriceTax: unitPrice.toFixed(2) });
    const unitPriceTaxable = (this.salesInvoiceForm.value.UnitPriceTax) ? this.salesInvoiceForm.value.UnitPriceTax : 0;
    const amount = qty * unitPriceTaxable;
    this.salesInvoiceForm.patchValue({ Amount: amount.toFixed(2) });
  }

  calulateSubTotalAndOutwardAmount() {
    let subTotalAmount = 0;
    let discount = 0;
    if (this.Items.length > 0) {
      this.Items.forEach(item => {
        const totalItemAmount = item.UnitPriceTax * item.DeliveryQty;
        discount += Number(item.DefaultSalesDiscount * totalItemAmount) / 100;
        subTotalAmount += totalItemAmount;
      });
    }

    this.salesInvoiceForm.patchValue({ subTotal: subTotalAmount.toFixed(2) });
    if (this.editData !== 'UPDATE') {
      this.totalAmount = subTotalAmount - discount;
      this.salesInvoiceForm.patchValue({ Discount: discount.toFixed(2) });
    } else if (this.editData === 'UPDATE') {
      this.totalAmount = subTotalAmount - this.salesInvoiceForm.value.Discount;
    }
    if (Number(this.salesInvoiceForm.value.Discount) === Number(discount.toFixed(2))) {
      this.calaculateDiscountValues();
    } else {
      this.calaculateOverAllDiscount();
    }
  }

  calaculateDiscountValues() {
    let discountigstAmount = 0;
    let discountcgstAmount = 0;
    let discountsgstAmount = 0;
    this.Items.forEach(item => {
      const totalItemAmount = item.UnitPriceTax * item.DeliveryQty;
      let individualDiscount = (totalItemAmount * item.DefaultSalesDiscount) / 100;
      individualDiscount = (isNaN(individualDiscount) ? 0 : individualDiscount);
      item.Discount_Amount = individualDiscount;
      if (this.sameStateStatus) {
        item.CGST_Amount = Number(((totalItemAmount - individualDiscount) * item.CGST) / 100).toFixed(2);
        discountcgstAmount += ((totalItemAmount - individualDiscount) * item.CGST) / 100;
        item.SGST_Amount = Number(((totalItemAmount - individualDiscount) * item.SGST) / 100).toFixed(2);
        discountsgstAmount += ((totalItemAmount - individualDiscount) * item.SGST) / 100;
      } else {
        item.IGST_Amount = Number(((totalItemAmount - individualDiscount) * item.IGST) / 100).toFixed(2);
        discountigstAmount += ((totalItemAmount - individualDiscount) * item.IGST) / 100;
      }
    });
    if (this.sameStateStatus) {
      this.salesInvoiceForm.patchValue({ CGST: discountcgstAmount.toFixed(2) });
      this.salesInvoiceForm.patchValue({ SGST: discountsgstAmount.toFixed(2) });
      this.totalAmount = this.totalAmount + discountcgstAmount + discountsgstAmount;
    } else {
      this.salesInvoiceForm.patchValue({ IGST: discountigstAmount.toFixed(2) });
      this.totalAmount = this.totalAmount + discountigstAmount;
    }
    this.calaculateFreightValues();
  }

  calaculateFreightValues() {
    let freightigstAmount = 0;
    let freightcgstAmount = 0;
    let freightsgstAmount = 0;
    let freighShare = 0;
    let itemShare = 0;
    const freight = (this.salesInvoiceForm.value.Freight) ? this.salesInvoiceForm.value.Freight : 0;
    if (freight) {
      this.Items.forEach(item => {
        const totalItemAmount = item.UnitPriceTax * item.DeliveryQty;
        itemShare = Number(100 / this.salesInvoiceForm.value.subTotal) * totalItemAmount;
        freighShare = (freight * itemShare) / 100;
        if (this.sameStateStatus) {
          item.CGST_Amount = (Number(item.CGST_Amount) + Number((freighShare * item.CGST) / 100)).toFixed(2);
          freightcgstAmount += (freighShare * item.CGST) / 100;
          item.SGST_Amount = (Number(item.SGST_Amount) + Number((freighShare * item.SGST) / 100)).toFixed(2);
          freightsgstAmount += (freighShare * item.SGST) / 100;
        } else {
          item.IGST_Amount = (Number(item.IGST_Amount) + Number((freighShare * item.IGST) / 100)).toFixed(2);
          freightigstAmount += (freighShare * item.IGST) / 100;
        }
      });

      //Commented by Kiran to check later 
      // const cgstFreight = freightcgstAmount;
      // const sgstFreight = freightsgstAmount;
      // const igstFreight = freightigstAmount;
      // this.salesInvoiceForm.value.IGST = (this.salesInvoiceForm.value.IGST) ? this.salesInvoiceForm.value.IGST : 0;
      // this.salesInvoiceForm.value.CGST = (this.salesInvoiceForm.value.CGST) ? this.salesInvoiceForm.value.CGST : 0;
      // this.salesInvoiceForm.value.SGST = (this.salesInvoiceForm.value.SGST) ? this.salesInvoiceForm.value.SGST : 0;
      // freightcgstAmount += Number(this.salesInvoiceForm.value.CGST);
      // freightsgstAmount += Number(this.salesInvoiceForm.value.SGST);
      // freightigstAmount += Number(this.salesInvoiceForm.value.IGST);
      // if (this.sameStateStatus) {
      //   this.salesInvoiceForm.patchValue({ CGST: freightcgstAmount.toFixed(2) });
      //   this.salesInvoiceForm.patchValue({ SGST: freightsgstAmount.toFixed(2) });
      //   this.totalAmount = this.totalAmount + Number(freight) + Number(cgstFreight) + Number(sgstFreight);
      // } else {
      //   this.salesInvoiceForm.patchValue({ IGST: freightigstAmount.toFixed(2) });
      //   this.totalAmount = this.totalAmount + Number(freight) + Number(igstFreight);
      // }
    }
    this.calaculateOtherValues();
  }

  calaculateOtherValues() {
    const otherCharges = (this.salesInvoiceForm.value.OtherCharges) ? this.salesInvoiceForm.value.OtherCharges : 0;
    if (otherCharges) {
      this.totalAmount += Number(otherCharges);
    }
    this.salesInvoiceForm.patchValue({ TotalOutwardAmount: this.totalAmount.toFixed(2) });
    this.salesInvoiceForm.patchValue({ PaymentAmount: this.totalAmount.toFixed(2) });
  }

  calaculateOverAllDiscount() {
    let discount = 0;
    let discountAmount = 0;
    let discountigstAmount = 0;
    let discountcgstAmount = 0;
    let discountsgstAmount = 0;
    let totaltagprice = 0 ;

    
      if (this.Items.length > 0 && this.salesInvoiceForm.value.subTotal > 0) {
        this.Items.forEach(item => {
          totaltagprice = totaltagprice + (item.UnitPrice * item.DeliveryQty);
        });
        this.Items.forEach(item => {
          const totalItemAmount = item.UnitPrice * item.DeliveryQty;


          if (Number(this.salesInvoiceForm.value.Discount)> 0)
          {
                discount = Number(100 / totaltagprice) * totalItemAmount;
                discountAmount = Number(discount * this.salesInvoiceForm.value.Discount) / 100;
                item.Discount_Amount = discountAmount;
          }
          item.unitPriceTax = Number(((totalItemAmount - discountAmount) * 100 ) / (item.IGST + 100)).toFixed(2);
          
          if (this.sameStateStatus) {
            item.CGST_Amount = Number(item.unitPriceTax *  (item.CGST)/100).toFixed(2);
            discountcgstAmount +=  Number(item.CGST_Amount) ;
            item.SGST_Amount = Number(item.unitPriceTax *  (item.SGST)/100).toFixed(2);
            discountsgstAmount +=   Number(item.SGST_Amount) ;
          } else {
            item.IGST_Amount = Number(item.unitPriceTax *  (item.IGST)/100).toFixed(2);
            discountigstAmount +=   Number(item.IGST_Amount) ;
          }
        });
        this.totalAmount = totaltagprice - this.salesInvoiceForm.value.Discount;
    
    }

    // Freight
    let freightigstAmount = 0;
    let freightcgstAmount = 0;
    let freightsgstAmount = 0;
    let freightShare = 0;
    let itemShare = 0;
    const freight = (this.salesInvoiceForm.value.Freight) ? this.salesInvoiceForm.value.Freight : 0;
    // if (freight) {
    //   this.Items.forEach(item => {
    //     const totalItemAmount = item.UnitPriceTax * item.DeliveryQty;
    //     itemShare = Number(100 / this.salesInvoiceForm.value.subTotal) * totalItemAmount;
    //     freightShare = Number(freight * itemShare) / 100;
    //     if (this.sameStateStatus) {
    //       item.CGST_Amount += (freightShare * item.CGST) / 100;
    //       freightcgstAmount += (freightShare * item.CGST) / 100;
    //       item.SGST_Amount += (freightShare * item.SGST) / 100;
    //       freightsgstAmount += (freightShare * item.SGST) / 100;
    //     } else {
    //       item.IGST_Amount += (freightShare * item.IGST_Amount) / 100;
    //       freightigstAmount += (freightShare * item.IGST) / 100;
    //     }
    //   });
    // }


    if (this.sameStateStatus) {
      this.salesInvoiceForm.patchValue({ CGST: (Number(discountcgstAmount) + Number(freightcgstAmount)).toFixed(2) });
      this.salesInvoiceForm.patchValue({ SGST: (Number(discountsgstAmount) + Number(freightsgstAmount)).toFixed(2) });
      this.totalAmount = this.totalAmount + Number(freight) + Number(freightcgstAmount) + Number(freightsgstAmount);
    } else {
      this.salesInvoiceForm.patchValue({ IGST: (Number(discountigstAmount) + Number(freightigstAmount)).toFixed(2) });
      this.totalAmount = this.totalAmount + Number(freight) ;
    }

    if (this.salesInvoiceForm.value.Discount > 0)
      this.totalAmount = totaltagprice - this.salesInvoiceForm.value.Discount;
    this.calaculateOtherValues();
  }

  // Remove Sales Invoice Item from table
  removeItems(i: number, itemData) {
    
    this.salesInvoiceForm.patchValue({ ItemID: itemData.ItemID });
    this.salesInvoiceForm.patchValue({ ItemName: itemData.ItemName });
    this.salesInvoiceForm.patchValue({ ItemClientID: itemData.ItemClientID });
    this.OtherItemsData.HSN = itemData.HSN;
    this.OtherItemsData.IGST = itemData.IGST;
    this.OtherItemsData.CGST = itemData.CGST;
    this.OtherItemsData.SGST = itemData.SGST;
    this.salesInvoiceForm.patchValue({ DeliveryQty: itemData.DeliveryQty });
    this.salesInvoiceForm.patchValue({ UnitPrice: itemData.UnitPrice });
    this.salesInvoiceForm.patchValue({ UnitPriceTax: itemData.UnitPriceTax });
    this.salesInvoiceForm.patchValue({ Amount: itemData.Amount });
    this.OtherItemsData.DefaultSalesDiscount = itemData.DefaultSalesDiscount;
    this.salesInvoiceForm.patchValue({ BarCode: itemData.BarCode });
    this.salesInvoiceForm.patchValue({ BatchSlNo: itemData.BatchSlNo });
    this.Items.splice(i, 1);
    //Start::Added for offer
    if(itemData.EnableWalletAmount == 'Y'){
      this.redeemableAmount = Number(this.redeemableAmount) - Number(itemData.UnitPriceTax);
      this.redeemableAmount=this.redeemableAmount.toFixed(2);
    }
    if(this.Items.length==0){
      this.redeemableAmount=0;
      this.BalanceInvoiceAmount=0;
      this.paymentTypes = [];
      this.paymentTypeTempList.forEach(element => {
        let obj = {
          ID: element.ID,
          Code: element.Code
        }
        this.paymentTypes.push(obj);
      });
    }else{
      let offerItemsList:any[]=this.Items.filter(item=>item.EnableWalletAmount=='Y');
      console.log(offerItemsList);
      if(offerItemsList!=null){
        if(offerItemsList.length==0){
          this.paymentTypes = [];
      this.paymentTypeTempList.forEach(element => {
        let obj = {
          ID: element.ID,
          Code: element.Code
        }
        this.paymentTypes.push(obj);
      });
        }
      }
    }
    
    //End
    this.calulateSubTotalAndOutwardAmount();
    this.clearSubFormItemsData();

  }

  async checkReddemPointBalance() {
    let inputData = {
      LocationID: this.user.LocationID,
      CustomerID: this.customerId,
      BalanceRedeemAmount: this.salesInvoiceForm.value.PaymentAmount
    }
   await this.salesinvoiceService.getRedeemBalance(inputData).subscribe(res => {
       this.redeemPointsMessage = res;
      if ( this.redeemPointsMessage != "" ||  this.redeemPointsMessage != null) {       
        return false;
      }else{
        return true;
      }
    });
  }
onPaymentTypeChange(paymentType){

  if(paymentType=="Cash"){
    this.salesInvoiceForm.patchValue({AccountID:1});
    this.accountName="CASH";
   
  }else if(paymentType=="RedeemPoint"){
    this.salesInvoiceForm.patchValue({AccountID:40000});
    this.accountName="Redeem Account";
  }else{
    this.salesInvoiceForm.patchValue({AccountID:null});
    this.accountName="";
  }
 
}
  // Add Payments
  addPayments() {
    let paymentType=this.salesInvoiceForm.value.PaymentType;
    let paymentAmount=this.salesInvoiceForm.value.PaymentAmount;
    let accountId= this.salesInvoiceForm.value.AccountID;
    if (accountId == 1) {
      this.accountName = "CASH";
    }
    if(accountId==null || this.accountName==""){
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Please select ledger'
      });
      return;
    }
    if (paymentType == "RedeemPoint" && (Number(paymentAmount) > Number(this.balanceRedeemPoints))) {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Payment amount should be less than or equl to redeem balance.'
      });
      return;
    }
    if (paymentType == "RedeemPoint" && (Number(paymentAmount) > Number(this.redeemableAmount))) {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Payment amount should be less than or equl to redeemable amount.'
      });
      return;
    }
       
    const repeatObj = {
      PaymentType: null,
      AccountID: null,
      AccountName: '',
      BankName: '',
      ChequeNumber: '',
      PaymentAmount: '',
    };
    let addPay = true;
    if (this.showDirectInvoice) {
      if (this.salesInvoiceForm.value.PaymentType && this.salesInvoiceForm.value.PaymentAmount
        && this.salesInvoiceForm.value.AccountID) {

        if (this.salesInvoiceForm.value.PaymentType === 'Cheque' || this.salesInvoiceForm.value.PaymentType === 'DemandDraft') {
          if (this.salesInvoiceForm.value.ChequeNumber === '' && this.salesInvoiceForm.value.BankName === '') {
            Swal.fire({
              type: 'warning',
              title: 'Warning',
              text: 'Please Enter Bank and Cheque Details'
            });
            addPay = false;
          }
        }

        if (this.salesInvoiceForm.value.PaymentType === 'Card') {
          if (this.salesInvoiceForm.value.BankName === '') {
            Swal.fire({
              type: 'warning',
              title: 'Warning',
              text: 'Please Enter Bank Details'
            });
            addPay = false;
          }
        }
        
        if (addPay) {
          repeatObj.PaymentType = this.salesInvoiceForm.value.PaymentType;
          repeatObj.AccountID = this.salesInvoiceForm.value.AccountID;
          repeatObj.AccountName = this.accountName;
          repeatObj.BankName = (this.salesInvoiceForm.value.BankName) ? this.salesInvoiceForm.value.BankName : '';
          repeatObj.ChequeNumber = (this.salesInvoiceForm.value.ChequeNumber) ? this.salesInvoiceForm.value.ChequeNumber : '';
          repeatObj.PaymentAmount = this.salesInvoiceForm.value.PaymentAmount;
          this.PaymentReceipts.push(repeatObj);
          let paymentAmount = 0;
          this.PaymentReceipts.forEach((payment) => {
            paymentAmount += Number(payment.PaymentAmount);
          });
          this.BalanceInvoiceAmount = Number(this.salesInvoiceForm.value.TotalOutwardAmount - paymentAmount).toFixed(2);
          this.clearSubFormPaymentsData();
        }

      } else {
        Swal.fire({
          type: 'warning',
          title: 'Warning',
          text: 'Please Select Payment Type and Enter Amount'
        });
      }
    } else {
      // && this.salesInvoiceForm.value.PaymentAmount    
      if (this.salesInvoiceForm.value.PaymentType  && this.salesInvoiceForm.value.AccountID) {

        if (this.salesInvoiceForm.value.PaymentType === 'Cheque' || this.salesInvoiceForm.value.PaymentType === 'DemandDraft') {
          if (this.salesInvoiceForm.value.ChequeNumber === '' && this.salesInvoiceForm.value.BankName === '') {
            Swal.fire({
              type: 'warning',
              title: 'Warning',
              text: 'Please Enter Bank and Cheque Details'
            });
            addPay = false;
          }
        }

        // if (this.salesInvoiceForm.value.PaymentType === 'Card') {
        //   if (this.salesInvoiceForm.value.BankName === '') {
        //     Swal.fire({
        //       type: 'warning',
        //       title: 'Warning',
        //       text: 'Please Enter Bank Details'
        //     });
        //     addPay = false;
        //   }
        // }

        if (addPay) {

          repeatObj.PaymentType = this.salesInvoiceForm.value.PaymentType;
          repeatObj.AccountID = this.salesInvoiceForm.value.AccountID;
          repeatObj.AccountName = this.accountName;
          repeatObj.BankName = (this.salesInvoiceForm.value.BankName) ? this.salesInvoiceForm.value.BankName : '';
          repeatObj.ChequeNumber = (this.salesInvoiceForm.value.ChequeNumber) ? this.salesInvoiceForm.value.ChequeNumber : '';
          repeatObj.PaymentAmount = this.salesInvoiceForm.value.Downpayment;
          this.PaymentReceipts.push(repeatObj);
          
          let obj = {
            PaymentType: null,
            AccountID: null,
            AccountName: '',
            BankName: '',
            ChequeNumber: '',
            PaymentAmount: '',
          };

          obj.PaymentType = "Loan";
          obj.AccountID = "0";
          obj.AccountName = "";
          obj.BankName = "Loan";
          obj.ChequeNumber = "";
          obj.PaymentAmount = this.salesInvoiceForm.value.LoanAmount;
          this.PaymentReceipts.push(obj);

          let paymentAmount = 0;
          this.PaymentReceipts.forEach((payment) => {
            paymentAmount += Number(payment.PaymentAmount);
          });
          this.BalanceInvoiceAmount = Number(this.salesInvoiceForm.value.TotalOutwardAmount - paymentAmount).toFixed(2);
          this.clearSubFormPaymentsData();

        }
      } else {
        Swal.fire({
          type: 'warning',
          title: 'Warning',
          text: 'Please Select Payment Type and Enter Amount'
        });
      }
    }

  }

  // Remove Payments
  removePayment(i: number) {
    this.PaymentReceipts.splice(i, 1);
    if (this.PaymentReceipts.length <= 0) {
      this.BalanceInvoiceAmount = Number(this.salesInvoiceForm.value.TotalOutwardAmount).toFixed(2);
    } else {
      let paymentAmount = 0;
      this.PaymentReceipts.forEach((payment) => {
        paymentAmount += Number(payment.PaymentAmount);
      });

      this.BalanceInvoiceAmount = Number(this.salesInvoiceForm.value.TotalOutwardAmount - paymentAmount).toFixed(2);
    }
  }

  // Clear Form Data
  clearSubFormItemsData() {
    this.salesInvoiceForm.patchValue({ ItemID: null });
    this.salesInvoiceForm.patchValue({ DeliveryQty: '' });
    this.salesInvoiceForm.patchValue({ UnitPrice: '' });
    this.salesInvoiceForm.patchValue({ UnitPriceTax: '' });
    this.salesInvoiceForm.patchValue({ Amount: '' });
    this.salesInvoiceForm.patchValue({ ItemClientID: null });
    this.salesInvoiceForm.patchValue({ ItemName: '' });
    this.salesInvoiceForm.patchValue({ BarCode: '' });
    this.salesInvoiceForm.patchValue({ BatchBalance: '' });
    this.salesInvoiceForm.patchValue({ BatchSlNo: '' });
    this.salesInvoiceForm.patchValue({ CategorySearch: null });
    this.salesInvoiceForm.patchValue({ ItemSearch: null });
    this.ItemImages = [];
    this.OtherItemsData = {};
  }

  // Clear Form Data
  clearSubFormPaymentsData() {
    this.salesInvoiceForm.patchValue({ PaymentType: 'Cash' });
    this.salesInvoiceForm.patchValue({ AccountID: 1 });
    this.salesInvoiceForm.patchValue({ BankName: '' });
    this.salesInvoiceForm.patchValue({ ChequeNumber: '' });
    this.salesInvoiceForm.patchValue({ PaymentAmount: '' });
    this.accountName="CASH";
  }

  clearData() {
    this.salesInvoiceForm.patchValue({ OutwardDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd') });
    this.salesInvoiceForm.patchValue({ ReceiverName: '-' });
    this.salesInvoiceForm.patchValue({ ReceiverPhone: '-' });
    this.salesInvoiceForm.patchValue({ TransporterName: '-' });
    this.salesInvoiceForm.patchValue({ WaybillNumber: '-' });
    this.salesInvoiceForm.patchValue({ CreateUserID: '' });
    this.salesInvoiceForm.patchValue({ EmployeeID: null });
    this.salesInvoiceForm.patchValue({ IGST: null });
    this.salesInvoiceForm.patchValue({ CGST: null });
    this.salesInvoiceForm.patchValue({ SGST: null });
    this.salesInvoiceForm.patchValue({ Freight: 0 });
    this.salesInvoiceForm.patchValue({ Discount: 0 });
    this.salesInvoiceForm.patchValue({ OtherCharges: 0 });
    this.salesInvoiceForm.patchValue({ TotalOutwardAmount: null });
    this.salesInvoiceForm.patchValue({ PlaceOfSupply: JSON.parse(localStorage.user).BranchName });
    this.salesInvoiceForm.patchValue({ SearchString: null });
    this.salesInvoiceForm.patchValue({ Address: '-' });
    this.salesInvoiceForm.patchValue({ Name: '' });
    this.salesInvoiceForm.patchValue({ MaterialSuppliedAt: this.suppliedLocations[0].ID });
    this.salesInvoiceForm.patchValue({ subTotal: null });
    this.salesInvoiceForm.patchValue({ SourceID: null });    
    this.salesInvoiceForm.patchValue({ Remarks: '' });
    this.salesInvoiceForm.patchValue({ PaymentType: 'Cash' });
    this.salesInvoiceForm.patchValue({ AccountID: 1 });
    //Finance module data
    this.showFinanceInvoice= false;
    this.showDirectInvoice= true;

    this.salesInvoiceForm.patchValue({ IsFinanceSale: false });    
    this.salesInvoiceForm.patchValue({ FinanceLedgerID: null });    
    this.salesInvoiceForm.patchValue({ Interest: '' });
    this.salesInvoiceForm.patchValue({ FinInvoiceAmount: '' });
    this.salesInvoiceForm.patchValue({ Downpayment: '' });
    this.salesInvoiceForm.patchValue({ ProcessingFee: '' });
    this.salesInvoiceForm.patchValue({ CardCharge: '' });
    this.salesInvoiceForm.patchValue({ TotalReceivable: '' });
    this.salesInvoiceForm.patchValue({ LoanAmount: '' });
    this.salesInvoiceForm.patchValue({ SubventionInterest: '' });
    this.salesInvoiceForm.patchValue({ SubventionAmount: '' });
    this.salesInvoiceForm.patchValue({ SubvenentionCGST: '' });
    this.salesInvoiceForm.patchValue({ SubvenentionSGST: '' });
    this.salesInvoiceForm.patchValue({ DisbursementAmount: '' });
    
    this.salesInvoiceForm.patchValue({ ScratchCardCount: 0 });

    this.salesInvoiceObj.OutwardClientID = '';
    this.Items = [];
    this.PaymentReceipts = [];
    this.editData = '';
    this.accountName = "CASH";
    this.balanceRedeemPoints = 0;
    this.showBalanceRedeemPoints = false;
    this.BalanceInvoiceAmount = 0;
    this.redeemableAmount=0;

  }

  resetForm() {
    this.salesInvoiceForm.reset();
    this.Items = [];
    this.PaymentReceipts = [];
    this.editData = '';
    this.clearData();
  }

  mapFormdataToObj(formData) {

    if (this.editData === 'UPDATE') {
      this.salesInvoiceObj.RequestType = 'UPDATE';
    } else if (this.editData === 'DELETE') {
      this.salesInvoiceObj.RequestType = 'DELETE';
    } else {
      this.salesInvoiceObj.RequestType = 'ADD';
    }

    this.salesInvoiceObj.CustomerCostCenterID= formData.CustomerCostCenterID;
    this.salesInvoiceObj.LocationID = this.user.LocationID;
    this.salesInvoiceObj.CreateUserID = this.user.EmployeeCode;
    this.salesInvoiceObj.OutwardDate = this.datePipe.transform(formData.OutwardDate, 'dd-MMM-yyyy');
    this.salesInvoiceObj.ReceiverName = formData.ReceiverName;
    this.salesInvoiceObj.ReceiverPhone = formData.ReceiverPhone;
    this.salesInvoiceObj.TransporterName = formData.TransporterName;
    this.salesInvoiceObj.ShippingAddress = formData.Address;
    this.salesInvoiceObj.WaybillNumber = formData.WaybillNumber;
    this.salesInvoiceObj.EmployeeID = formData.EmployeeID;
    this.salesInvoiceObj.Discount = formData.Discount;
    this.salesInvoiceObj.IGST = formData.IGST;
    this.salesInvoiceObj.CGST = formData.CGST;
    this.salesInvoiceObj.SGST = formData.SGST;
    this.salesInvoiceObj.Freight = formData.Freight;
    this.salesInvoiceObj.OtherCharges = formData.OtherCharges;
    this.salesInvoiceObj.TotalOutwardAmount = formData.TotalOutwardAmount;
    this.salesInvoiceObj.PlaceOfSupply = formData.PlaceOfSupply;
    this.salesInvoiceObj.Remarks = formData.Remarks;
    this.salesInvoiceObj.MaterialSuppliedAt = formData.MaterialSuppliedAt;
    this.salesInvoiceObj.YearNo = this.user.YearNo;
    this.salesInvoiceObj.Items = this.Items;
    this.salesInvoiceObj.PaymentReceipts = this.PaymentReceipts;
    this.salesInvoiceObj.CustomerEnteredValue = formData.SearchString;
    this.salesInvoiceObj.SourceID = formData.SourceID;
    this.salesInvoiceObj.IsFinanceSale =false;

    if(this.showFinanceInvoice){
      this.salesInvoiceObj.IsFinanceSale = true;
      this.salesInvoiceObj.FinanceLedgerID = formData.FinanceLedgerID;
      this.salesInvoiceObj.InvoiceAmount = formData.TotalOutwardAmount;
      this.salesInvoiceObj.Downpayment = formData.Downpayment;
      this.salesInvoiceObj.ProcessingFee = formData.ProcessingFee;
      this.salesInvoiceObj.Interest = formData.Interest;
      this.salesInvoiceObj.CardCharge = formData.CardCharge;
      this.salesInvoiceObj.TotalReceivable = formData.TotalReceivable;
      this.salesInvoiceObj.LoanAmount = formData.LoanAmount;
      this.salesInvoiceObj.SubventionInterest = formData.SubventionInterest;
      this.salesInvoiceObj.SubvenentionCGST = formData.SubvenentionCGST;
      this.salesInvoiceObj.SubvenentionSGST = formData.SubvenentionSGST;
      this.salesInvoiceObj.DisbursementAmount = formData.DisbursementAmount;
    } 

    this.salesInvoiceObj.ScratchCardCount = formData.ScratchCardCount;

    console.log("Sale Invo Obj::",JSON.stringify(this.salesInvoiceObj));
    return this.salesInvoiceObj;
  }

  submitSalesInvoice() {
    let currentFinYear = this.user.YearNo;
    if (currentFinYear !== this.previewFinYear) {
      Swal.fire({
        type: 'warning',
        title: 'Warning',
        text: 'Previous year invoice(s) cannot be modified/deleted'
      });
      return;
    }
    if (this.editData === 'DELETE') {
      this.saveSalesInvoice();
    } else {
      if (this.Items.length > 0) {
        this.saveSalesInvoice();
      } else {
        Swal.fire({
          type: 'warning',
          title: 'Warning',
          text: 'Please add items'
        });
      }
    }
  }

  updateECommerceItemStockStatus(eCommerceItemId, itemQuantity) {
    let stockStatus = "instock";
    if (itemQuantity > 0) {
      stockStatus = "instock";
    } else {
      stockStatus = "outofstock";
    }
    let eCommerceItemData = {
      stock_status: stockStatus
    }  
    this.inventoryService.updateItemsOneCommerce(eCommerceItemId, eCommerceItemData).subscribe(async res => {
    });
  }

  // Save Sales Invoice Data
  saveSalesInvoice() {
        
    this.salesinvoiceService.saveSalesInvoice(this.mapFormdataToObj(this.salesInvoiceForm.value)).subscribe(async res => {
      if (res.status === 200) {
        let eCommerceItemId = res.body.ItemQuantity[0].ECommerceItemID;
        let itemQuantity = res.body.ItemQuantity[0].BalanceQuantity;
        let environment = Constants.ENVIRONMENT;
        if (eCommerceItemId !== 0 && environment === 'production') {
          await this.updateECommerceItemStockStatus(eCommerceItemId, itemQuantity);
        }
        this.stockOutwards = [];
        this.getSaleInvoices();
        // this.refreshItemsData();
        if (this.editData !== 'DELETE') {
          Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Sale Invoice Successfully Saved, Outward Ref.- ' + res.body.OutwardClientID
          });
          const mywindow = window.open();
          mywindow.document.write(res.body.PrintFormat);
          mywindow.print();
        } else {
          Swal.fire({
            type: 'success',
            title: 'Deleted!',
            text: 'Sale Invoice Successfully Deleted'
          });
        }
        this.clearData();
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  getCatagories() {
    this.itemService.getCatagories().subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.CategoryID,
          Code: item.CategoryName
        };
        this.itemList.push(resData);
      });
      this.catagories = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  async getItemsData() {
    this.itemsData = [];
    try {
      let data = await localForage.getItem('itemsData');
      this.itemsData = data;
      this.itemsFlag = true;
    } catch (err) {
      console.log(err);
    }
  }

 async refreshItemsData() {
    this.itemsData = [];
    this.salesInvoiceForm.patchValue({ ItemSearch: null });
    const inputObj = {
      LocationID: this.user.LocationID,
      CategoryId: (this.salesInvoiceForm.value.CategorySearch) ? this.salesInvoiceForm.value.CategorySearch : 0,
    };
    await this.stockReportService.getClosingStock(inputObj).subscribe(result => {
      if (result.body.length > 0) {
        this.itemList = [];
        let resData = {};
        result.body.forEach(item => {
          resData = {
            ID: item.ItemID,
            Code: item.DisplayText
          };
          this.itemList.push(resData);
        });
        this.itemsData = this.itemList;
        localForage.setItem('itemsData', this.itemsData);
      }
    });
  }

  onCloseHandled() {
    this.display = 'none';
  }

  getItemDetails(event) {
    this.isItemOfferEnabled = "";
    const inputData = {
      LocationID: this.user.LocationID,
      ItemID: event.ID,
      CustomerID: this.salesInvoiceObj.Supplier_Customer_Branch_Number
    };

    this.salesinvoiceService.getItemDetails(inputData).subscribe(res => {
      if (res.body) {
        let UnitPriceTax = 0;
        this.salesInvoiceForm.patchValue({ ItemName: res.body.ItemName });
        this.salesInvoiceForm.patchValue({ ItemID: res.body.ItemID });
        this.salesInvoiceForm.patchValue({ ItemClientID: res.body.ItemClientID });
        this.salesInvoiceForm.patchValue({ UnitPrice: res.body.SalePrice.toFixed(2) });
        this.salesInvoiceForm.patchValue({ UnitPriceTax: res.body.SalePrice.toFixed(2) });
        this.salesInvoiceForm.patchValue({ BarCode: res.body.BarCode });
        this.salesInvoiceForm.patchValue({ BatchSlNo: res.body.BatchSlNo });
        this.salesInvoiceForm.patchValue({ BatchBalance: res.body.BatchBalance });
        this.ItemImages = res.body.FilePath;
        this.IGST = res.body.IGST;
        this.CGST = res.body.CGST;
        this.SGST = res.body.SGST;
        this.DefaultSalesDiscount = res.body.DefaultSalesDiscount;

        this.isItemOfferEnabled = res.body.EnableWalletAmount;

        const inputDataForGst = {
          ItemID: res.body.ItemID,
          SupplierNumber: this.salesInvoiceObj.Supplier_Customer_Branch_Number,
          LocationID: this.user.LocationID
        };
        this.getGstDetails(inputDataForGst);
        if (this.sameStateStatus) {
          UnitPriceTax = res.body.SalePrice / (100 + Number(Number(this.CGST) + Number(this.SGST))) * 100;
        } else {
          UnitPriceTax = res.body.SalePrice / (100 + Number(this.IGST)) * 100;
        }
        this.salesInvoiceForm.patchValue({ UnitPriceTax: UnitPriceTax.toFixed(2) });
        this.salesInvoiceForm.patchValue({ Amount: (res.body.SalePrice * this.salesInvoiceForm.value.DeliveryQty).toFixed(2) });
        this.qty.nativeElement.focus();
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    });
  }

  itemSearchFn(term: any, item: any) {
    term = term.split(' ');
    return term.every((el) => {
      return item.Code.toLocaleLowerCase().indexOf(el.toLocaleLowerCase()) > -1;
    });
  }

  getSalesSource() {
    let locationId = this.user.LocationID;
    this.salesourcesrvice.getSalesSource(locationId).subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.SourceID,
          Code: item.SourceName
        };
        this.itemList.push(resData);
      });
      this.SalesSourceList = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  getLedgers() {
    this.salesinvoiceService.getLedgers(this.user.LocationID).subscribe(res => {
      this.itemList = [];
      this.itemList.push({
        ID: '0',
        Code: 'Credit'
      });
      let resData = {};
      res.forEach(item => {
        if (item.ShowInSaleInvoice === 'Y') {
          resData = {
            ID: item.AccountID,
            Code: item.AccountName
          };
          this.itemList.push(resData);
        }
      });
      this.ledgers = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  selectLedger(event) {
    this.accountName = event.Code;
  }

}
