import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SalesInvoiceComponent } from 'src/app/erp/components/sales-invoice/sales-invoice.component';
import { ExportexcelService } from 'src/app/services/exportexcel/exportexcel.service';
import { ModulemappingService } from 'src/app/erp/services/modulemapping/modulemapping.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user: any;
  role: string;
  hide: boolean = true;
  permissions: any = [];
  locations: any = [];
  itemList: any = [];
  selectedLocation:any=null;
  pendingActionsList:any[]=[];
  disableExportBtn=true;
  disableInwrdExportBtn =true;
  exportData:any[]=[];
  display: string;
  pendingInwardsCount:any=0;
  pendingInwardsList:any[]=[];
  
  columnDefs = [
    { headerName: '', field: 'actions', width: 60, cellRenderer: (params) => {
      return '<i class="fas fa-edit btn text-primary p-0 ml-2 mr-2" data-action-type="edit" ></i>' +
        '<i class="fas fa-trash-alt btn text-danger p-0" data-action-type="delete"></i>';
    } },
    { headerName: 'ActionID', field: 'ActionID', width: 100, hide: true },
    { headerName: 'ActionType', field: 'ActionType', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'Action Description', field: 'ActionDescription', width: 400, resizable: true, filter: true, sortable: true },
    { headerName: 'CreateDt', field: 'CreatedDate', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'ETA', field: 'Eta', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'Status', field: 'Status', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'Comment', field: 'Comment', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'RequestedBy', field: 'RequestedBy', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'AssignedTo', field: 'AssignedTo', width: 100, resizable: true, filter: true, sortable: true },
    { headerName: 'HoursWorked', field: 'HoursWorked', width: 100, resizable: true, filter: true, sortable: true },
  ];

  columnDefsPenInwrds = [   
    { headerName: 'SourceLocationID', field: 'SourceLocationID', width: 100, hide: true },
    { headerName: 'SourceBranch', field: 'SourceBranch', width: 150, resizable: true, filter: true, sortable: true },  
    { headerName: 'OutwardNo.', field: 'SourceOutwardNumber', width: 130, resizable: true, filter: true, sortable: true,cellStyle: {textAlign: 'right'}},
    { headerName: 'OutwardDisplayNo.', field: 'SourceOutwardDisplayNumber', width: 150, resizable: true, filter: true, sortable: true },
    { headerName: 'OutwardDt.', field: 'SourceOutwardDate', width: 110, resizable: true, filter: true, sortable: true },
  ];

  public gridOptions = {
    pagination: true,
    paginationAutoPageSize: true,
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    enableCellTextSelection:true,
    rowData: null
  };
  statusList:any[]=["New","In Progress","Pending","Completed"];
  actionTypeList:any[]=["Change Request","Issue","Data Correction"];
  priorityList:any[]=["P1","P2","P3","P4"];
  moduleList:any[]=["Accounts","Admin","Analitics","Business","HRMS","Sales & Distribution ","Service"];
 
  @ViewChild(SalesInvoiceComponent,{static:false}) salesInvoiceComponent: SalesInvoiceComponent;

  constructor(private fb: FormBuilder, private router:Router, private datePipe: DatePipe, private loginService: LoginService, 
    private excelService:ExportexcelService,private moduleMappingService: ModulemappingService,private route: Router, ) {}

  public pendingActionsForm = this.fb.group({
    RequestType: "ADD",
    ActionID: [0, Validators.required],
    ActionDescription: ['', Validators.required],
    Eta: [null, Validators.required],
    CreatedDate: [null],
    UpdatedDate: [null],
    ModuleName: [null, Validators.required],
    Priority: [null, Validators.required],
    Status: [null, Validators.required],
    Comment: ['-', Validators.required],
    RequestedBy: ['-', Validators.required],
    HoursWorked: ['', Validators.required],
    AssignedTo: ['', Validators.required],
    ActionType: [null, Validators.required],
    CreateUserID:""
  });

  public changePasswordForm = this.fb.group({

    CurrentPassword:[null, Validators.required],
    NewPassword:[null, [Validators.required , Validators.minLength(8),Validators.maxLength(12)]],
    ConfirmPassword:[null, [Validators.required , Validators.minLength(8),Validators.maxLength(12)]],
    CreateUserID: '',
    EmployeeID:'',
    EmployeeCode:'',
    LocationID:0

  });

  showChangePasswordForm:boolean = true;
  showChangePasswordMsg:boolean=false;


  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.role = JSON.parse(localStorage.getItem('role'));
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.getLocationCodes();
    this.resetPasswordFrom();
    this.showChangePasswordForm=true;
    this.showChangePasswordMsg = false;
    this.getPendingInwardsCount();
    // this.getModules();

  }
  getModules() {
    this.moduleMappingService.getModules().subscribe(res => {
      this.moduleList = [];
      this.moduleList = res.body;
    });
  }
  getPendingInwardsCount(){
    let locationId = this.user.LocationID;
    this.loginService.getPendingInwardsCount(this.user.LocationID).subscribe(res=>{
      this.pendingInwardsCount=res.Count;     
    });
  }
  getPendingInwards(){
    this.disableInwrdExportBtn = true;
    this.exportData=[];
    let locationId = this.user.LocationID;
    this.loginService.getPendingInwards(this.user.LocationID).subscribe(res=>{
      this.pendingInwardsList=res.balanceOutwardHeaders; 
      this.pendingInwardsList.map(element => element.SourceOutwardDate = this.datePipe.transform(element.SourceOutwardDate, 'dd-MMM-yyyy'));
      this.pendingInwardsList.forEach(element=>{
        let obj={
          SourceBranch:element.SourceBranch,
          OutwardNo:element.SourceOutwardNumber,
          OutwardDisplayNo:element.SourceOutwardDisplayNumber,
          OutwardDt:element.SourceOutwardDate,
        };
        this.exportData.push(obj);
      });
      if(this.pendingInwardsList.length>0){
        this.disableInwrdExportBtn=false;
      }
    });
  }
  openChangePasswordForm(){
    this.display='block';
  }  
  myFunction() {
    this.hide = !this.hide;
  }
  onCloseHandled() {
    this.display = 'none';
  }
  savePassword(){

    if(!this.changePasswordForm.valid){
      return;
    }
    let currentPassword = this.changePasswordForm.value.CurrentPassword;
    let newPassword = this.changePasswordForm.value.NewPassword;
    let confirmPassword = this.changePasswordForm.value.ConfirmPassword;

    if (currentPassword === confirmPassword) {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'New Password and Current Password should not be same'
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: 'New Password and Confirm Password should match'
      });
      return;
    }

    this.loginService.savePassword(this.changePasswordForm.value).subscribe(res=>{

      if(res.status ===  201){
        this.resetPasswordFrom();
        this.showChangePasswordForm=false;
        this.showChangePasswordMsg = true;
      }
      // if (res.status === 201 ) {
      //   Swal.fire({
      //     type: 'success',
      //     title: 'Updated.!',
      //     text: 'Password changed successfully.!'
      //   });
      // }
      
    });
    // this.loginService.logout();
    // location.reload(true);
    //this.router.navigate(['/login']);
  }
  resetPasswordFrom(){
    this.changePasswordForm = this.fb.group({
      CurrentPassword:[null, Validators.required],
      NewPassword:[null, Validators.required],
      ConfirmPassword:[null, Validators.required],
      CreateUserID: this.user.EmployeeCode,
      EmployeeID: this.user.EmployeeID,
      EmployeeCode: this.user.EmployeeCode,
      LocationID:this.user.LocationID
    });
  }
  getActionItems(){
    this.exportData=[];
    this.loginService.getPendingActions().subscribe(res=>{
      this.pendingActionsList=res;
      this.pendingActionsList.map(element=>{element.CreatedDate=this.datePipe.transform(element.CreatedDate,'dd-MMM-yyyy')});
      this.pendingActionsList.map(element=>{element.UpdatedDate=this.datePipe.transform(element.UpdatedDate,'dd-MMM-yyyy')});
      this.pendingActionsList.map(element=>{element.Eta=this.datePipe.transform(element.Eta,'dd-MMM-yyyy')});
      if(this.pendingActionsList.length>0){
        this.pendingActionsList.forEach(element=>{
          
          let obj = {
            ActionType:element.ActionType,
            ActionDescription:element.ActionDescription,
            ModuleName:element.ModuleName,
            Priority:element.Priority,
            CreatedDate:element.CreatedDate,
            UpdatedDate:element.UpdatedDate,
            Eta:element.Eta,
            Status:element.Status,
            Comment:element.Comment,
            RequestedBy:element.RequestedBy,
            AssignedTo:element.AssignedTo,
            HoursWorked:element.HoursWorked,
          }
          this.exportData.push(obj);
        });
        this.disableExportBtn=false;
      }
      this.setActionItemDefaultValues();
    });
  }
  saveActionItems() {
    this.pendingActionsForm.patchValue({CreateUserID:this.user.EmployeeCode});
    if(this.pendingActionsForm.invalid){
      return false;
    }
    this.loginService.savePendingActions(this.pendingActionsForm.value).subscribe(res => {
      if (res.status === 201) {
        if(this.pendingActionsForm.value.RequestType=="ADD"){
          Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Action item saved successfully'
          });
        }
        else if(this.pendingActionsForm.value.RequestType=="UPDATE"){
          Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Action item updated successfully'
          });
        }
        else if(this.pendingActionsForm.value.RequestType=="DELETE"){
          Swal.fire({
            type: 'success',
            title: 'Success',
            text: 'Action item deleted successfully'
          });
        }
        this.getActionItems();
      }
    });
  }
  setActionItemDefaultValues() {
    this.pendingActionsForm = this.fb.group({
      RequestType: "ADD",
      ActionID: [0, Validators.required],
      ActionDescription: ['', Validators.required],
      Eta: [null, Validators.required],
      CreatedDate: [null],
      UpdatedDate: [null],
      ModuleName: [null, Validators.required],
      Priority: [null, Validators.required],
      Status: [null, Validators.required],
      Comment: ['-', Validators.required],
      RequestedBy: ['-', Validators.required],
      HoursWorked: ['', Validators.required],
      AssignedTo: ['', Validators.required],
      ActionType: [null, Validators.required],
      CreateUserID: this.user.EmployeeCode
    });
  }
  exportExel(){
    this.excelService.exportAsExcelFile(this.exportData,'pending_actions_list');
  }
  exportPendingInwardsExel(){
    this.excelService.exportAsExcelFile(this.exportData,'pending_inwards_list');
  }
  EditFill(rowData) { 
    this.pendingActionsForm = this.fb.group({
      RequestType: "UPDATE",
      ActionID: [rowData.data.ActionID, Validators.required],
      ActionDescription: [rowData.data.ActionDescription, Validators.required],
      Eta: [this.datePipe.transform(rowData.data.Eta,"yyyy-MM-dd"), Validators.required],
      CreatedDate: [this.datePipe.transform(rowData.data.CreatedDate,"yyyy-MM-dd")],
      UpdatedDate: [this.datePipe.transform(rowData.data.UpdatedDate,"yyyy-MM-dd")],
      ModuleName: [rowData.data.ModuleName, Validators.required],
      Priority: [rowData.data.Priority, Validators.required],
      Status: [rowData.data.Status, Validators.required],
      Comment: [rowData.data.Comment, Validators.required],
      RequestedBy: [rowData.data.RequestedBy, Validators.required],
      AssignedTo: [rowData.data.AssignedTo, Validators.required],
      ActionType: [rowData.data.ActionType, Validators.required],
      HoursWorked: [rowData.data.HoursWorked, Validators.required],
      CreateUserID:this.user.EmployeeCode
    });
  }
  onRowClicked(e) {
    if (e.event.target !== undefined) {
      const data = e.data;
      const actionType = e.event.target.getAttribute('data-action-type');

      switch (actionType) {
        case 'edit':
          return this.EditFill(e);
        case 'delete':
          return this.deleteActionItem(data);
      }
    }
  }
  deleteActionItem(data: any) {
    this.pendingActionsForm = this.fb.group({
      RequestType: "DELETE",
      ActionID: data.ActionID,
      ActionDescription: [data.ActionDescription, Validators.required],
      Eta: [data.Eta, Validators.required],
      Status: [data.Status, Validators.required],
      Comment: [data.Comment, Validators.required],
      RequestedBy: [data.RequestedBy, Validators.required],
      CreateUserID:this.user.EmployeeCode 
    });
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.saveActionItems();
      } else {
        this.setActionItemDefaultValues();
      }
    });
  }
  redirectHome(){
    this.router.navigate(['/home']);
  }
  getLocationCodes() {
    this.loginService.getLocationCodes().subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.LocationID,
          Code: item.Name
        };
        this.itemList.push(resData);
      });
      this.locations = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }
  logout() {
    console.log("calling logout.");
    let userData={
      LocationID:this.user.LocationID,
      EmployeeCode:this.user.EmployeeCode
    }
    this.loginService.updateLogoutStatus(userData).subscribe(res=>{
      console.log("RES::",res);
    });
    this.loginService.logout();
    // location.reload(true);  
  }
}
